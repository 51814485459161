
import './DynamicBackground.scss';

function DynamicBackground() {
  return (
    <div className="DynamicBackground-outer">
        <div className="DynamicBackground-inner">
            <div className="DynamicBackground-innermost">
            </div>
        </div>
    </div>
  );
}

export default DynamicBackground;
